<template>
  <v-container fluid fill-height class="pa-0">
    <messenger
      v-if="ready"
      v-model="messages"
      requireSmsPhone
      :receiverSMSPhone="patient.smsPhone"
      :readonly="showRequireSMSPhone"
      @submit="handleSendMessage"
    ></messenger>
    <div v-else class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </v-container>
</template>

<style scoped>
.sms-message {
  position: absolute;
  bottom: 0;
  height: 72px;
  width: 100%;
  z-index: 10;
  background: #eee;
}

.lds-ellipsis {
  display: inline-block;
  position: absolute;
  width: 80px;
  left: 50%;
  transform: translate(-50%);
}
.lds-ellipsis div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ddd;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>

<script>
import { mapActions, mapState } from 'vuex'
import Messenger from '../shared-ui/components/messenger/Messenger.vue'
import get from 'lodash/get'
import bus from '@/core/helpers/bus'

export default {
  components: {
    Messenger,
  },
  data: () => {
    return {
      ready: false,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('conversation', ['conversation', 'messages', 'unreadMessagesCount']),
    ...mapState('patient', ['patient']),
    ...mapState('org', ['org']),
    showRequireSMSPhone() {
      return !get(this.patient, 'smsPhone')
    },
    patientId() {
      return this.patient.id
    },
  },
  watch: {
    unreadMessagesCount(newCount, oldCount) {
      if (newCount > oldCount) {
        this.getMessages({
          orgId: this.org.id,
          patientId: this.patientId,
        })
      }
    },
  },
  methods: {
    // ...mapActions('conversation', ['sendMessage']),
    ...mapActions('conversation', ['decMessengerOpenCount', 'incMessengerOpenCount', 'getMessages', 'sendMessage']),
    handleSendMessage(content) {
      this.sendMessage({
        orgId: this.org.id,
        patientId: this.patientId,
        content,
      })
    },
  },
  async mounted() {
    if (this.patient && !this.patient.smsPhone) {
      bus.$emit('smssnack:show', this.patient)
    }

    await this.getMessages({
      orgId: this.org.id,
      patientId: this.patientId,
    })

    this.ready = true

    // update the frequency for check for unread
    // TODO: messages interval
    this.incMessengerOpenCount()
  },
  beforeDestroy() {
    this.decMessengerOpenCount()
  },
}
</script>